import Web3 from "web3";
import SmartContract from "../../ABI/ColdBeerClub.json";
import { balanceOf, totalSupply } from "../contract/contractActions";

const initialConnectRequest = () => {
	return {
		type: "INITIAL_CONNECTION_REQUEST",
	};
};

const initialConnectSuccess = (payload) => {
	return {
		type: "INITIAL_CONNECTION_SUCCESS",
		payload: payload,
	};
};

const noInitialAccountConnected = (payload) => {
	return {
		type: "NO_INITIAL_ACCOUNT_CONNECTED",
		payload: payload,
	};
};

const connectRequest = () => {
	return {
		type: "CONNECTION_REQUEST",
	};
};

const connectionSuccess = (result) => {
	return {
		type: "CONNECTION_SUCCESS",
		payload: result,
	};
};

const connectionError = (result) => {
	return {
		type: "CONNECTION_ERROR",
		payload: result,
	};
};

const updateAccountRequest = (payload) => {
	return {
		type: "UPDATE_ACCOUNT",
		payload: payload,
	};
};

export const connect = () => {
	return async (dispatch) => {
		if (window.ethereum) {
			dispatch(connectRequest());
			const findMetaMaskProvider = () => {
				if (window.ethereum.providers) {
					return window.ethereum.providers.find(
						(provider) => provider.isMetaMask
					);
				} else {
					return window.ethereum;
				}
			};
			if (findMetaMaskProvider().isMetaMask) {
				let web3 = new Web3(findMetaMaskProvider());
				const contract = new web3.eth.Contract(
					SmartContract.abi,
					"0x72Ab7A324b54D93742cc277EeE56c8D91f0A37Ab"
				);
				try {
					const accounts = await findMetaMaskProvider().request({
						method: "eth_requestAccounts",
					});
					const networkID = await findMetaMaskProvider().request({
						method: "net_version",
					});
					dispatch(
						connectionSuccess({
							account: accounts[0],
							networkID: networkID,
							contract: contract,
							web3: web3,
						})
					);
					dispatch(totalSupply(contract));
					dispatch(balanceOf(contract, accounts[0]));
					findMetaMaskProvider().on("accountsChanged", (accounts) => {
						dispatch(updateAccount(accounts[0], contract));
					});
					findMetaMaskProvider().on("chainChanged", (chainId) => {
						window.location.reload();
					});
				} catch (err) {
					console.log(err);
					dispatch(connectionError());
				}
			} else {
				dispatch(connectionError("Install Metamask"));
				window.open("https://metamask.app.link/dapp/coldbeer.club");
			}
		} else {
			dispatch(connectionError("Install Metamask"));
			window.open("https://metamask.app.link/dapp/coldbeer.club");
		}
	};
};

export const checkIfConnected = () => {
	return async (dispatch) => {
		if (window.ethereum) {
			dispatch(initialConnectRequest());
			const findMetaMaskProvider = () => {
				if (window.ethereum.providers) {
					return window.ethereum.providers.find(
						(provider) => provider.isMetaMask
					);
				} else {
					return window.ethereum;
				}
			};
			if (findMetaMaskProvider().isMetaMask) {
				let web3 = new Web3(findMetaMaskProvider());
				const contract = new web3.eth.Contract(
					SmartContract.abi,
					"0x72Ab7A324b54D93742cc277EeE56c8D91f0A37Ab"
				);
				findMetaMaskProvider().on("chainChanged", (chainId) => {
					window.location.reload();
				});
				dispatch(totalSupply(contract));
				try {
					const accounts = await web3.eth.getAccounts().then((res) => res);
					const networkID = await web3.eth.getChainId().then((res) => res);
					if (accounts.length) {
						dispatch(balanceOf(contract, accounts[0]));
						dispatch(
							initialConnectSuccess({
								account: accounts[0],
								networkID: networkID,
								contract: contract,
								web3: web3,
							})
						);
						findMetaMaskProvider().on("accountsChanged", (accounts) => {
							dispatch(updateAccount(accounts[0], contract));
						});
					} else {
						dispatch(noInitialAccountConnected());
					}
				} catch (err) {
					dispatch(noInitialAccountConnected());
					console.log(err);
				}
			} else {
				dispatch(noInitialAccountConnected("Install Metamask"));
			}
		} else {
			dispatch(noInitialAccountConnected("Install Metamask"));
		}
	};
};

export const updateAccount = (account, contract) => {
	return async (dispatch) => {
		if (!account) {
			window.location.reload();
		} else {
			dispatch(updateAccountRequest(account));
			dispatch(balanceOf(contract, account));
		}
	};
};

export const switchEthereumChain = (callback) => {
	return async (dispatch) => {
		const findMetaMaskProvider = () => {
			if (window.ethereum.providers) {
				return window.ethereum.providers.find(
					(provider) => provider.isMetaMask
				);
			} else {
				return window.ethereum;
			}
		};
		if (findMetaMaskProvider().isMetaMask) {
			await findMetaMaskProvider()
				.request({
					method: "wallet_switchEthereumChain",
					params: [{ chainId: "0x1" }],
				})
				.then((res) => res)
				.catch((err) => callback(false));
		}
	};
};
