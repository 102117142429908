import { createStore, applyMiddleware, combineReducers } from "redux";
import blockchainReducer from "./blockchain/blockchainReducer";
import contractReducer from "./contract/contractReducer";
import thunk from "redux-thunk";

const rootReducer = combineReducers({
	blockchain: blockchainReducer,
	contract: contractReducer,
});
const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
