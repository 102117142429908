import { Box, Button } from "@chakra-ui/react";
import { connect } from "../../redux/blockchain/blockchainActions";
import { useDispatch, useSelector } from "react-redux";

const ConnectButton = (props) => {
	const dispatch = useDispatch();
	const { account, loading } = useSelector((state) => state.blockchain);
	const { balanceOf } = useSelector((state) => state.contract);
	const contractLoading = useSelector((state) => state.contract.loading);

	const showBalanceOf = (
		<p style={{ marginRight: "7px", fontWeight: "normal" }}>{balanceOf} 🍺</p>
	);
	return (
		<Box {...props}>
			{loading || contractLoading ? (
				<Button
					isLoading
					borderRadius="0"
					size="sm"
					minW="163px"
					background="linear-gradient(to right, #7F7FD5, #86A8E7, #91EAE4)"
					_hover={{ transform: "scale(1.1)" }}
					_active={{
						background: "linear-gradient(to right, #7F7FD5, #86A8E7, #91EAE4)",
					}}
					fontFamily="Poppins"
					color="#000"
				/>
			) : (
				<Button
					borderRadius="0"
					size="sm"
					minW="163px"
					background="linear-gradient(to right, #7F7FD5, #86A8E7, #91EAE4)"
					_hover={{ transform: "scale(1.1)" }}
					_active={{
						background: "linear-gradient(to right, #7F7FD5, #86A8E7, #91EAE4)",
					}}
					fontFamily="Poppins"
					fontWeight={"700"}
					color="#000"
					onClick={(e) => {
						if (account.length) {
							e.preventDefault();
						} else {
							dispatch(connect());
						}
					}}
				>
					{account.length === 0 ? null : showBalanceOf}
					{account.length === 0
						? "CONNECT WALLET"
						: account.slice(0, 4).toUpperCase() +
						  "...." +
						  account.slice(-5).toUpperCase()}
				</Button>
			)}
		</Box>
	);
};

export default ConnectButton;
