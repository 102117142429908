import React from "react";
import {
	Box,
	Flex,
	Heading,
	Image,
	Container,
	Text,
	Link,
	Button,
} from "@chakra-ui/react";
import grid_image from "../../assets/images/content/CBC-Grid.webp";

const Story = () => {
	return (
		<Container
			as="section"
			maxW={"container.xl"}
			p={["0 1rem", null, "0 1.5rem"]}
			mb={"5rem"}
		>
			<Heading
				textAlign={"center"}
				color={"yellow.400"}
				display={["block", null, "none"]}
				fontSize={["3xl", "4xl"]}
				mb={"2rem"}
				fontFamily={"Poppins"}
				fontWeight={"700"}
			>
				Our Story
			</Heading>

			<Flex
				alignItems={"center"}
				flexDirection={["column-reverse", null, "column", "row"]}
				gridGap={["2rem", null, "3.5rem", "2rem"]}
			>
				<Box w={["100%", null, null, "50%", "55%", "60%"]}>
					<Heading
						as="h1"
						fontFamily={"Poppins"}
						fontWeight={"700"}
						color={"yellow.400"}
						display={["none", null, "block"]}
						fontSize={["1.3rem", null, "2rem"]}
					>
						Our Story
					</Heading>
					<Text
						fontFamily="Poppins"
						color="gray.200"
						fontSize={["md", null, "sm", "sm"]}
						mt={[0, null, "1rem"]}
					>
						Cold Beer Club started out, as most great ideas do, over a cold beer
						after a long day in the office. One of the ideas to come out of a
						particularly long night of cold beer club was a way to improve
						productivity of our team. Whilst floored, that idea soon turned into
						a full blown side project driven to help businesses hire better. The
						Cold beer club was where we came to discuss our ideas, to create and
						to test. And of course to unwind after a busy day.
						<br />
						<br />
						Cold beer club soon evolved into pyme. Where we are now working
						incredibly hard to build a better future of work for all. We believe
						that hiring is broken. We believe that web 2 is broken and we
						passionately believe in a future where the best person for the job,
						gets the job. We are excited to build a company that democratises
						work and cold beer club is the start of that.
						<br />
						<br />
						The funds raised from CBC will be used to help us scale our team and
						build a better working future for all. And we want to heavily reward
						our early supporters for being with us on our mission, thats you!{" "}
						<br />
						<br />
						If you have any questions, want to get involved or we can help in
						anyway, please come chat to us on Discord, all of our founding team
						are there.
					</Text>
					<Flex
						justifyContent={"space-evenly"}
						flexDirection={["column", null, "row"]}
						m={["1rem auto", null, "3.5rem 0 0", "1rem 0 0"]}
						gridGap={"1rem"}
						maxW={["300px", null, "none"]}
					>
						{[
							{ title: "Learn more about Pyme", link: "https://pyme.team" },
							{
								title: "Join our Discord",
								link: "https://discord.gg/SPwEyHbyyF",
							},
						].map((el, i) => {
							return (
								<Button
									key={i}
									as={Link}
									isExternal
									href={el.link}
									bg={"transparent"}
									fontFamily={"Poppins"}
									fontWeight={"400"}
									color={"#fff"}
									border={"2px solid"}
									borderColor={"yellow.400"}
									_hover={{ background: "transparent" }}
								>
									{el.title}
								</Button>
							);
						})}
					</Flex>
				</Box>

				<Box w={["100%", null, "60%", "55%", "45%", "40%"]}>
					<Image src={grid_image} w="100%" />
				</Box>
			</Flex>
		</Container>
	);
};

export default Story;
