const initialState = {
	loading: false,
	web3: "",
	smartContract: null,
	account: [],
	networkID: "",
	error: "",
};

const blockchainReducer = (state = initialState, action) => {
	switch (action.type) {
		case "INITIAL_CONNECTION_REQUEST":
			return {
				...state,
				loading: true,
			};
		case "INITIAL_CONNECTION_SUCCESS":
			return {
				...state,
				loading: false,
				account: action.payload.account,
				networkID: action.payload.networkID,
				web3: action.payload.web3,
				smartContract: action.payload.contract,
			};
		case "NO_INITIAL_ACCOUNT_CONNECTED":
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case "CONNECTION_REQUEST":
			return {
				...state,
				loading: true,
			};
		case "CONNECTION_SUCCESS":
			return {
				loading: false,
				web3: action.payload.web3,
				account: action.payload.account,
				networkID: action.payload.networkID,
				smartContract: action.payload.contract,
				error: "",
			};
		case "CONNECTION_ERROR":
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case "UPDATE_ACCOUNT":
			return {
				...state,
				account: action.payload,
			};
		default:
			return state;
	}
};

export default blockchainReducer;
