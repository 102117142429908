const requestBalanceOf = () => {
	return {
		type: "REQUEST_BALANCEOF",
	};
};

const requestTotalSupply = () => {
	return {
		type: "REQUEST_TOTALSUPPLY",
	};
};

const getBalanceOf = (payload) => {
	return {
		type: "GET_BALANCEOF",
		payload: payload,
	};
};

const balanceOfError = () => {
	return {
		type: "BALANCEOF_ERROR",
	};
};

const getTotalSupply = (payload) => {
	return {
		type: "GET_TOTALSUPPLY",
		payload: payload,
	};
};

const totalSupplyError = () => {
	return {
		type: "TOTALSUPPLY_ERROR",
	};
};

export const balanceOf = (contract, account) => {
	return async (dispatch) => {
		dispatch(requestBalanceOf());

		try {
			const balanceOf = await contract.methods
				.balanceOf(account)
				.call()
				.then((res) => res);
			dispatch(getBalanceOf(balanceOf));
		} catch (err) {
			console.log(err);
			dispatch(balanceOfError());
		}
	};
};

export const totalSupply = (contract) => {
	return async (dispatch) => {
		dispatch(requestTotalSupply());

		try {
			const totalSupply = await contract.methods
				.totalSupply()
				.call()
				.then((res) => res);
			dispatch(getTotalSupply(totalSupply));
		} catch (err) {
			console.log(err);
			dispatch(totalSupplyError());
		}
	};
};
