import React from "react";
import { Box, Container, Flex, Image, Link } from "@chakra-ui/react";
import DiscordLogo from "../../assets/svg/DiscordLogo";
import TwitterLogo from "../../assets/svg/TwitterLogo";
import OpenseaLogo from "../../assets/svg/OpenseaLogo";
import logo from "../../assets/images/pyme.webp";

const JoinUs = () => {
	return (
		<Container as="footer" maxW={"container.xl"} padding={"1.5rem"}>
			<Flex alignItems={"center"}>
				<Flex alignItems={"center"}>
					<Image src={logo} maxW={"115px"} />
				</Flex>
				<Flex
					flexWrap={"wrap"}
					w="fit-content"
					gridGap={["1rem", null, "3rem"]}
					m="0 0 0 auto"
				>
					{[
						{
							logo: (
								<Box w={["1.5rem", null, "2rem"]}>
									<Link isExternal href="https://discord.gg/SPwEyHbyyF">
										<DiscordLogo w={"100%"} />
									</Link>
								</Box>
							),
							title: "Discord",
							link: "#",
						},
						{
							logo: (
								<Box w={["1.5rem", null, "2rem"]}>
									<Link isExternal href="https://twitter.com/coldbeer_club">
										<TwitterLogo w={"100%"} />
									</Link>
								</Box>
							),
							title: "Twitter",
							link: "#",
						},
						{
							logo: (
								<Box w={["1.5rem", null, "2rem"]}>
									<Link
										isExternal
										href="https://opensea.io/collection/coldbeerclub"
									>
										<OpenseaLogo w={"100%"} />
									</Link>
								</Box>
							),
							title: "Opensea",
							link: "#",
						},
					].map((el, i) => {
						return <Box key={i}>{el.logo}</Box>;
					})}
				</Flex>
			</Flex>
		</Container>
	);
};

export default JoinUs;
