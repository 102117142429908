import { Container, Flex, Box, Text, Heading, Image } from "@chakra-ui/react";
import img1 from "../../assets/images/beta.png";
import img2 from "../../assets/images/bounty.png";
import img4 from "../../assets/images/airdrop.png";
import img5 from "../../assets/images/discount.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Card() {
	const perks = [
		{
			title: "Lifetime zero fee on pyme.",
			img: img5,
			desc: "Must hold NFT at beta launch to qualify.",
			bg: "linear-gradient(to bottom right, #FC5C7D, #6A82FB)",
		},
		{
			title: "pyme token airdrop.",
			img: img4,
			desc: "10% of issued pyme tokens will be airdropped to cold beer club holders upon token release.",
			bg: "linear-gradient(to bottom right, #59C173, #a17fe0, #5D26C1)",
		},
		{
			title: "BETA access to pyme.",
			img: img1,
			desc: "When pyme is open for beta access, CBC holders will get first access.",
			bg: "linear-gradient(to bottom right, #fc00ff, #00dbde)",
		},

		{
			title: "First access to bounty program and DAO.",
			img: img2,
			desc: "NFT holders will be granted first access to the bounty program and DAO.",
			bg: "linear-gradient(to bottom right, #FEAC5E,#C779D0, #4BC0C8)",
		},
	];

	return (
		<Container
			maxW={"container.xl"}
			as="section"
			p={["1rem", null, "1.5rem"]}
			mb="5rem"
		>
			<Heading
				as="h1"
				color="yellow.400"
				textAlign={["center", null, "left"]}
				fontSize={["3xl", "4xl"]}
				fontFamily={"Poppins"}
				fontWeight={"700"}
			>
				Perks
			</Heading>

			<Flex
				mt={"2rem"}
				direction="row"
				flexWrap={"wrap"}
				justifyContent={["space-around", null, "space-between"]}
				gridRowGap={"2rem"}
				gridColumnGap={"0.5rem"}
			>
				{perks.map((item, index) => {
					return (
						<Box key={index}>
							<Box
								background={item.bg}
								p={"1.5rem"}
								borderRadius={"15px"}
								maxW={"250px"}
								minH={"370px"}
								display={"flex"}
								flexDirection={"column"}
							>
								<Box>
									<Image src={item.img} maxW={"130px"} m="0 auto" />
								</Box>

								<Flex flexDirection={"column"} gridGap={"1rem"}>
									<Box>
										<Text
											fontFamily={"Poppins"}
											fontWeight="400"
											fontSize={"1.1rem"}
											lineHeight={"21px"}
											fontWeight={"bold"}
											mt={"1rem"}
										>
											{item.title}
										</Text>
									</Box>
									<Box mt={"1rem"}>
										<Text
											lineHeight={"21px"}
											fontFamily={"Poppins"}
											fontWeight={"400"}
										>
											{item.desc}
										</Text>
									</Box>
								</Flex>
							</Box>
						</Box>
					);
				})}
			</Flex>
		</Container>
	);
}
