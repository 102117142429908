import "./App.css";
import { ChakraProvider } from "@chakra-ui/react";
import Home from "./components/home/Home";
import About from "./components/about/About";
import FAQs from "./components/faqs/FAQs";
import Carousel from "./components/carousel/Carousel";
import Team from "./components/team/Team";
import Story from "./components/story/Story";
import JoinUs from "./components/join us/JoinUs";
import Mint from "./components/mint/Mint";
import Breakdown from "./components/breakdown/Breakdown";
import Perks from "./components/perks/Perks";

function App() {
	return (
		<ChakraProvider>
			<div className="App" style={{ background: "#000" }}>
				<Home />
				<About />
				<Breakdown />
				<Perks />
				<FAQs />
				<Mint />
				<Team />
				<Story />
				<Carousel />
				<JoinUs />
			</div>
		</ChakraProvider>
	);
}

export default App;
