import { Container, Heading, Box, Flex, Text, Button } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import bgVideo from "../../assets/video/bg-video.mp4";
import NavbarC from "../navbar/NavbarC";
import gif from "../../assets/images/bg-gif.gif";

const Home = () => {
	const [showBg, setShowBg] = useState(true);
	useEffect(() => {
		if (document.getElementById("video-bg")) {
			playVideo();
		}
	}, []);
	const playVideo = async () => {
		await document
			.getElementById("video-bg")
			.play()
			.then((res) => res)
			.catch((err) => {
				setShowBg(false);
			});
	};
	return (
		<>
			<Box position={"relative"} as={"section"} mb="5rem">
				<NavbarC />
				{showBg ? (
					<Box
						as="video"
						preload="metadata"
						loop
						autoPlay
						playsInline
						webkit-playsinline
						muted
						controls={false}
						position={"absolute"}
						w="100%"
						h="100%"
						objectFit={"cover"}
						id="video-bg"
					>
						<Box as="source" src={bgVideo} type="video/mp4" />
					</Box>
				) : (
					<Box
						position={"absolute"}
						w="100%"
						h="100%"
						objectFit={"cover"}
						background={`url(${gif})`}
						backgroundPosition={"center"}
						backgroundSize={"cover"}
						backgroundRepeat={"no-repeat"}
					/>
				)}

				<Box w="100%" h="100%" position={"absolute"} bg="rgba(0,0,0, 0.3)" />
				<Box
					w="100%"
					h="30px"
					position={"absolute"}
					bg="linear-gradient(360deg, rgba(2, 0, 36, 0) 7%, rgba(0, 0, 0, 1) 88%)"
				/>

				<Container
					maxW={"container.xl"}
					minH={"100vh"}
					display={"flex"}
					flexDirection={"column"}
					alignItems={"center"}
					color={"gray.200"}
					justifyContent={"center"}
					position={"relative"}
				>
					<Box>
						<Heading
							as="h1"
							color={"gray.200"}
							fontFamily={"Poppins"}
							fontWeight={"800"}
							textAlign={"center"}
							fontSize={["3rem", "4rem", "5rem"]}
						>
							Cold{" "}
							<Box as="span" color="yellow.400">
								Beer
							</Box>{" "}
							Club
						</Heading>
						<Text
							color="yellow.400"
							fontWeight={"Bold"}
							textAlign={"center"}
							mt={1}
						>
							Drink Responsibly
						</Text>
					</Box>
					<Flex
						w={"100%"}
						justifyContent={"space-between"}
						mt={"5rem"}
						flexDirection={["column", null, "row"]}
						gridGap={"1rem"}
						alignItems={"center"}
						maxW={"700px"}
					>
						<Button
							as="a"
							href="#mint"
							variant={"ghost"}
							fontFamily={"Poppins"}
							fontWeight={"400"}
							color={"#fff"}
							fontSize={["lg", null, "1.5rem"]}
							_hover={{
								transform: "scale(1.1)",
								textDecoration: "underline",
							}}
							border={"2px solid "}
							borderColor="yellow.400"
							w={"250px"}
						>
							MINT
						</Button>
						<Button
							variant={"ghost"}
							as="a"
							href="https://discord.gg/SPwEyHbyyF"
							target={"_blank"}
							fontFamily={"Poppins"}
							fontWeight={"400"}
							color={"#fff"}
							fontSize={["lg", null, "1.5rem"]}
							_hover={{
								transform: "scale(1.1)",
								textDecoration: "underline",
							}}
							border={"2px solid "}
							borderColor="yellow.400"
							w={"250px"}
						>
							JOIN DISCORD
						</Button>
					</Flex>
				</Container>
			</Box>
		</>
	);
};

export default Home;
