import {
	Box,
	Container,
	Heading,
	Flex,
	VStack,
	StackDivider,
} from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";

const Breakdown = () => {
	const roadmapObj = [
		{
			text: "We pay back our mums, pay our team and keep the lights on.",
			amount: "10%",
		},
		{
			text: " pyme token and DAO setup costs, community funds to support other NFT's and floor sweep plus a few community projects and surprises we have planned.",
			amount: "40%",
		},
		{
			text: "Seed funding for pyme. Allowing us to scale, hire developers, open bounties and build out the pyme platform.",
			amount: "50%",
		},
	];
	return (
		<Container
			maxW={"container.xl"}
			as="section"
			p={["0 1rem", null, "0 1.5rem"]}
			mb="5rem"
			id="roadmap"
		>
			<Heading
				as="h1"
				color="yellow.400"
				textAlign={["center", null, "left"]}
				fontSize={["3xl", "4xl"]}
				fontFamily={"Poppins"}
				fontWeight={"700"}
			>
				Where does the money go?
			</Heading>

			<VStack
				mt={"2rem"}
				gridRowGap={"1rem"}
				fontSize={["sm", "md", "lg"]}
				align={"stretch"}
				divider={<StackDivider borderColor="yellow.400" />}
			>
				{roadmapObj.map((el, i) => {
					return (
						<Flex
							key={i}
							fontFamily={"Poppins"}
							fontWeight={"400"}
							color={"gray.200"}
							alignItems={"center"}
							gridGap={["1.5rem", null, "2rem"]}
						>
							<Box
								fontFamily={"Poppins"}
								color={"gray.200"}
								fontSize={"3xl"}
								fontWeight={"800"}
							>
								{el.amount}
							</Box>
							<Box>
								<Text>{el.text}</Text>
							</Box>
						</Flex>
					);
				})}
			</VStack>
		</Container>
	);
};

export default Breakdown;
