import { Box, Flex, Text, Heading, Container } from "@chakra-ui/react";
import MintingBox from "./MintingBox/MintingBox";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const Mint = () => {
	const [fallbackSupply, setFallbackSupply] = useState("");
	const { totalSupply } = useSelector((state) => state.contract);
	const { networkID } = useSelector((state) => state.blockchain);

	useEffect(() => {
		fetchTotalSupply();
	}, []);

	const fetchTotalSupply = async () => {
		const options = { method: "GET", headers: { Accept: "application/json" } };

		const data = await fetch(
			"https://api.opensea.io/api/v1/collection/coldbeerclub/stats",
			options
		)
			.then((response) => response.json())
			.then((response) => {
				setFallbackSupply(response.stats.total_supply);
			})
			.catch((err) => console.log(err));
	};

	return (
		<Container
			as="section"
			maxW={"container.xl"}
			p={["0 1rem", null, "0 1.5rem"]}
			mb="5rem"
			id="mint"
		>
			<Heading
				as="h1"
				fontFamily={"Poppins"}
				fontWeight={"700"}
				color={"yellow.400"}
				textAlign={["center", null, "left"]}
				fontSize={["3xl", "4xl"]}
			>
				Join Cold Beer Club
			</Heading>

			<Flex
				mt={"2rem"}
				justifyContent={"space-between"}
				gridGap={"1rem"}
				flexDirection={["column", null, "row"]}
			>
				<Box flexBasis={"100%"}>
					<MintingBox />
				</Box>
				<Flex
					fontFamily={"Poppins"}
					color={"gray.200"}
					fontSize={["sm", "md", "md", "xl"]}
					justifyContent={"space-between"}
					flexWrap={"wrap"}
					flexBasis={"90%"}
					mt={["2rem", null, "0"]}
					gridRowGap={["2rem", null, "0"]}
				>
					<Box textAlign={"center"} width={"50%"}>
						<Text>January 04, 2022</Text>
						<Text fontWeight={"Bold"} color={"yellow.400"}>
							Launch Date
						</Text>
					</Box>
					<Box textAlign={"center"} width={"50%"}>
						<Text>
							{window.ethereum && networkID == 1 ? totalSupply : fallbackSupply}
							/10000
						</Text>
						<Text fontWeight={"Bold"} color={"yellow.400"}>
							Total Supply
						</Text>
					</Box>
					<Box textAlign={"center"} width={"50%"}>
						<Text>100 Max</Text>
						<Text fontWeight={"Bold"} color={"yellow.400"}>
							NFT Per Address
						</Text>
					</Box>
				</Flex>
			</Flex>
		</Container>
	);
};

export default Mint;
