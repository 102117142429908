import { useEffect } from "react";
import { Box, Image, Link } from "@chakra-ui/react";
import logo from "../../assets/images/pyme.webp";
import Drawer from "../drawer/Drawer";
import { useDispatch } from "react-redux";
import { checkIfConnected } from "../../redux/blockchain/blockchainActions";
import ConnectButton from "../connect button/ConnectButton";

const NavbarC = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(checkIfConnected());
	}, []);
	return (
		<Box
			bg="transparent"
			as="header"
			display={"flex"}
			alignItems={"center"}
			p={"0 7%"}
			borderBottom={"0.1rem solid rgba(255,255,255,0.3)"}
			justifyContent={["space-between", null, "initial"]}
			position={"absolute"}
			width={"100%"}
			zIndex={"1"}
		>
			<Link href="#">
				<Image src={logo} w={["100px", null, "115px"]} />
			</Link>
			<Box as={"nav"} display={["none", null, "block"]}>
				{[
					{ text: "Funding", link: "#roadmap" },
					{ text: "Team", link: "#team" },
					{ text: "Pyme", link: "https://pyme.team", newTab: true },
					{
						text: "Discord",
						link: "https://discord.gg/SPwEyHbyyF",
						newTab: true,
					},
				].map((el, i) => {
					return (
						<Link
							fontFamily={"Poppins"}
							margin={"0 1rem"}
							color={"#fff"}
							href={el.link}
							key={i}
							target={el.newTab ? "_blank" : ""}
						>
							{el.text}
						</Link>
					);
				})}
			</Box>
			<Box m="0 0 0 auto">
				<ConnectButton
					display={["none", "none", "inline-block", "inline-block"]}
				/>
			</Box>
			<Drawer />
		</Box>
	);
};

export default NavbarC;
