import React from "react";
import {
	Drawer,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	Box,
	Image,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/hooks";
import { Button } from "@chakra-ui/button";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Center, Flex, Link } from "@chakra-ui/layout";
import DiscordLogo from "../../assets/svg/DiscordLogo";
import TwitterLogo from "../../assets/svg/TwitterLogo";
import OpenseaLogo from "../../assets/svg/OpenseaLogo";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../../redux/blockchain/blockchainActions";
import beer from "../../assets/images/beer-emoji.png";

const MobileDrawer = () => {
	const dispatch = useDispatch();
	const { account, loading } = useSelector((state) => state.blockchain);
	const contractLoading = useSelector((state) => state.contract.loading);
	const { balanceOf } = useSelector((state) => state.contract);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const btnRef = React.useRef();

	const showBalanceOf = (
		<p style={{ marginRight: "7px", fontWeight: "normal" }}>{balanceOf} 🍺</p>
	);

	return (
		<>
			<Button
				ref={btnRef}
				background="transparent"
				onClick={onOpen}
				display={["inline-flex", null, "none"]}
				_hover={{ background: "none" }}
				_active={{ background: "none" }}
				_focus={{ boxShadow: "none" }}
			>
				<HamburgerIcon color="#fff" w={25} h={25} />
			</Button>
			<Drawer
				isOpen={isOpen}
				placement="left"
				onClose={onClose}
				finalFocusRef={btnRef}
			>
				<DrawerOverlay />
				<DrawerContent background="#131313" color="gray.200">
					<DrawerHeader borderBottom={"1px solid #E2E8F0"}>
						<Center>
							{loading || contractLoading ? (
								<Button
									isLoading
									borderRadius="0"
									size="sm"
									w="80%"
									background="linear-gradient(to right, #7F7FD5, #86A8E7, #91EAE4)"
									_hover={{ transform: "scale(1.1)" }}
									_active={{
										background:
											"linear-gradient(to right, #7F7FD5, #86A8E7, #91EAE4)",
									}}
									color="#000"
									fontFamily="Poppins"
									fontWeight={"700"}
								/>
							) : (
								<Button
									onClick={(e) => {
										if (account.length) {
											e.preventDefault();
										} else {
											dispatch(connect());
										}
									}}
									borderRadius="0"
									size="sm"
									w="80%"
									background="linear-gradient(to right, #7F7FD5, #86A8E7, #91EAE4)"
									_hover={{ transform: "scale(1.1)" }}
									_active={{
										background:
											"linear-gradient(to right, #7F7FD5, #86A8E7, #91EAE4)",
									}}
									color="#000"
									fontFamily="Poppins"
									fontWeight={"700"}
								>
									{account.length === 0 ? null : showBalanceOf}
									{account.length === 0
										? "CONNECT WALLET"
										: account.slice(0, 4).toUpperCase() +
										  "....." +
										  account.slice(-5).toUpperCase()}
								</Button>
							)}
							<DrawerCloseButton top={"0.85rem"} />
						</Center>
					</DrawerHeader>

					<DrawerBody fontFamily="Poppins">
						<Box fontFamily="Poppins" color="yellow.400">
							{[
								{ title: "Pyme", link: "https://pyme.team", internal: false },
								{ title: "Funding", link: "#roadmap", internal: true },
								{ title: "Team", link: "#team", internal: true },
								{
									title: "Discord",
									link: "https://discord.gg/SPwEyHbyyF",
									internal: false,
								},
							].map((el, i) => {
								return (
									<Flex
										key={i}
										ml={"1.4rem"}
										alignItems={"center"}
										mb={"1.5rem"}
										gridGap={"5px"}
									>
										<Image src={beer} w="17px" h="17px" />
										{el.internal ? (
											<Link
												href={el.link}
												fontSize={"lg"}
												onClick={() => {
													document
														.getElementById(el.link.slice(1))
														.scrollIntoView({ behavior: "smooth" });
													setTimeout(() => {
														onClose();
													}, 500);
												}}
											>
												{el.title}
											</Link>
										) : (
											<Link href={el.link} fontSize={"lg"} isExternal>
												{el.title}
											</Link>
										)}
									</Flex>
								);
							})}
						</Box>
					</DrawerBody>

					<DrawerFooter borderTop={"1px solid #E2E8F0"}>
						<Flex w="100%" justifyContent="space-evenly" alignItems="center">
							<Link isExternal href="https://discord.gg/SPwEyHbyyF">
								<DiscordLogo w="25px" />
							</Link>

							<Link isExternal href="https://twitter.com/coldbeer_club">
								<TwitterLogo w="25px" />
							</Link>

							<Link
								isExternal
								href="https://opensea.io/collection/coldbeerclub"
							>
								<OpenseaLogo w="25px" />
							</Link>
						</Flex>
					</DrawerFooter>
				</DrawerContent>
			</Drawer>
		</>
	);
};

export default MobileDrawer;
