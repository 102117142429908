import {
	Box,
	Container,
	Flex,
	Heading,
	Image,
	Link,
	Text,
} from "@chakra-ui/react";
import beer from "../../assets/images/content/beergif.gif";

const About = () => {
	return (
		<Container
			as="section"
			maxW={"container.xl"}
			p={["0 1rem", null, "0 1.5rem"]}
			mb="5rem"
		>
			<Heading
				as="h1"
				fontFamily={"Poppins"}
				fontWeight={"700"}
				color={"yellow.400"}
				textAlign={["center", null, "left"]}
				display={["block", null, "none"]}
				fontSize={["3xl", "4xl"]}
			>
				About Us
			</Heading>

			<Flex
				mt={"2rem"}
				alignItems={"center"}
				bg="rgba(0,0,0,0.45)"
				borderRadius={"15px"}
				flexDirection={["column", null, "row"]}
				gridGap={"2rem"}
			>
				<Box w={["100%", null, "4xl"]}>
					<Image
						src={beer}
						bg="gray.200"
						m={["0 auto", null, "0 auto 0 0"]}
						w="100%"
						maxW={["300px", null, "none"]}
					/>
				</Box>

				<Box>
					<Heading
						mb={"1rem"}
						as="h1"
						fontFamily={"Poppins"}
						fontWeight={"700"}
						color="yellow.400"
						fontSize={["1.3rem", null, "1.5rem", "2rem"]}
						display={["none", null, "block"]}
					>
						About Us
					</Heading>
					<Text
						fontFamily="Poppins"
						fontWeight={"400"}
						color="gray.200"
						fontSize={["md", null, "sm", "md"]}
					>
						10,000 unique beers ready to fuel your greatest work! Because
						everybody knows, beer fuels productivity! Each original beer is
						designed to fuel your working future and support a team on a mission
						to build a better working world.
						<br />
						<br />
						Cold beer club is the NFT launch project and pre-seed funding of
						pyme. We are launching cold beer club to bring together people who
						support our mission, to reward our early backers and to bring some
						light hearted joy to our community. Learn more{" "}
						<Link
							href="https://pyme.team"
							isExternal
							color={"blue.300"}
							textDecoration={"underline"}
						>
							here
						</Link>
						<br />
						<br />
						<Box as={"span"} color={"red.400"}>
							Warning:
						</Box>{" "}
						Too much beer will make objects appear{" "}
						<Box as="span" filter={"blur(0.7px)"}>
							fuzzier than they are in real life
						</Box>
					</Text>
				</Box>
			</Flex>
		</Container>
	);
};

export default About;
