import {
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Container,
	Box,
	Heading,
	Text,
	Link,
	Flex,
} from "@chakra-ui/react";

const FAQs = () => {
	const faqs = [
		{
			title: "What is Cold Beer Club?",
			text: (
				<Text>
					Cold beer club is the NFT project and seed funding of{" "}
					<Link
						href="https://pyme.team"
						isExternal
						color={"blue.300"}
						textDecoration={"underline"}
					>
						Pyme
					</Link>
					: a community on a mission to build a better working world
				</Text>
			),
		},
		{
			title: "What is a NFT?",
			text: (
				<Text>
					NFTs, are pieces of digital content linked to the blockchain. Unlike
					cryptocurrency, these assets are fungible, meaning they cannot be
					replaced or exchanged with another identical one of the same value.
				</Text>
			),
		},
		{
			title: "What wallet is needed?",
			text: <Text>We recommend you use Metamask.</Text>,
		},
		{
			title: "How do I mint?",
			text: (
				<Text>
					<Box>1{") "} Connect your wallet using Metamask.</Box>
					<Box>
						<br />2{") "}Enter the mint quantity.
					</Box>
					<Box>
						<br />3{") "}Click on the 'MINT' button.
					</Box>
					<Box>
						<br />4{") "}Approve transaction from Metamask.
					</Box>
					<Box>
						<br />5{") "}You're all set! Show your NFT on our{" "}
						<Link
							href="https://discord.gg/SPwEyHbyyF"
							isExternal
							color={"blue.300"}
							textDecoration={"underline"}
						>
							Discord
						</Link>
					</Box>
				</Text>
			),
		},
		{
			title: "How to view your NFT?",
			text: (
				<Text>
					You can view your NFT from a marketplace, such as{" "}
					<Link
						href="https://opensea.io"
						isExternal
						color={"blue.300"}
						textDecoration={"underline"}
					>
						opensea.io
					</Link>
					. Once you connect your wallet, you will be able to see your NFT
					{"(s)"} on your profile
				</Text>
			),
		},
		{
			title: "What is the mint price?",
			text: (
				<Text>
					Each beer is 0.05 ETH. We believe that this is the fairest way to
					allow everyone access.
				</Text>
			),
		},
		{
			title: "How many can I mint?",
			text: (
				<Text>
					You can mint 10 beers at a time. No more than 100 beers can be minted
					by a single wallet to allow as many people as possible to have access
					to the project
				</Text>
			),
		},
		{
			title: "Is there a royalty fee for trading my NFT?",
			text: <Text>Yes, any trades incur a 10% fee from us.</Text>,
		},
		{
			title: "Why beer?",
			text: (
				<Text>
					Read more about our jouney in our story. Most of our good ideas
					started over a cold beer.
				</Text>
			),
		},
		{
			title: "How is the project related to pyme?",
			text: (
				<Text>
					Cold beer club is the seed fuding project of pyme and gives all CBC
					members incredible early access and lifetime benefits
				</Text>
			),
		},
	];

	return (
		<Container
			maxW={"container.xl"}
			as="section"
			p={["0 1rem", null, "0 1.5rem"]}
			mb={"5rem"}
		>
			<Heading
				as="h1"
				color="yellow.400"
				textAlign={["center", null, "left"]}
				fontSize={["3xl", "4xl"]}
				fontFamily={"Poppins"}
				fontWeight={"700"}
			>
				Frequently Asked Questions
			</Heading>
			<Flex
				justifyContent={"center"}
				gridGap={["0", null, null, "2rem"]}
				flexDirection={["column", null, "column", "row"]}
			>
				<Box mt={"2rem"} w={["100%", null, "100%", "50%"]}>
					{/* <Accordion m={"2rem auto 0"} allowMultiple maxW={"50rem"}> */}
					{faqs.slice(0, 4).map((faq, i) => {
						return (
							<Accordion allowMultiple key={i}>
								<AccordionItem
									marginBottom={"1rem"}
									border={"1px solid"}
									fontFamily={"Poppins"}
									fontWeight={"400"}
									color={"yellow.400"}
								>
									<h2>
										<AccordionButton>
											<Box flex="1" textAlign={"left"}>
												{faq.title}
											</Box>
											<AccordionIcon />
										</AccordionButton>
									</h2>
									<AccordionPanel
										color={"gray.200"}
										pb={4}
										fontSize={["sm", null, "md"]}
									>
										{faq.text}
									</AccordionPanel>
								</AccordionItem>
							</Accordion>
						);
					})}
					{/* </Accordion> */}
				</Box>

				<Box w={["100%", null, "100%", "50%"]}>
					<Box mt={["0", null, null, "2rem"]}>
						{/* <Accordion m={"2rem auto 0"} allowMultiple maxW={"50rem"}> */}
						{faqs.slice(4, 8).map((faq, i) => {
							return (
								<Accordion allowMultiple key={i}>
									<AccordionItem
										marginBottom={"1rem"}
										border={"1px solid"}
										fontFamily={"Poppins"}
										fontWeight={"400"}
										color={"yellow.400"}
									>
										<h2>
											<AccordionButton>
												<Box flex="1" textAlign={"left"}>
													{faq.title}
												</Box>
												<AccordionIcon />
											</AccordionButton>
										</h2>
										<AccordionPanel
											color={"gray.200"}
											pb={4}
											fontSize={["sm", null, "md"]}
										>
											{faq.text}
										</AccordionPanel>
									</AccordionItem>
								</Accordion>
							);
						})}
						{/* </Accordion> */}
					</Box>
				</Box>

				{/* <Box w={["100%", null, null, "50%"]}>
          <Box mt={["0", null, null, "2rem"]}>
             <Accordion m={"2rem auto 0"} allowMultiple maxW={"50rem"}> 
             {faqs.slice(8, 9).map((faq, i) => {
              return (
                <Accordion allowMultiple key={i}>
                  <AccordionItem
                    marginBottom={"1rem"}
                    border={"1px solid"}
                    fontFamily={"PRegular"}
                    color={"yellow.400"}
                  >
                    <h2>
                      <AccordionButton>
                        <Box flex="1" textAlign={"left"}>
                          {faq.title}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      color={"gray.200"}
                      pb={4}
                      fontSize={["sm", null, "md"]}
                    >
                      {faq.text}
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              );
            })} 
             </Accordion> 
          </Box>
        </Box> */}
			</Flex>
		</Container>
	);
};

export default FAQs;
