const initialState = {
	loading: "",
	supplyLoading: "",
	totalSupply: "",
	balanceOf: "",
};

const contractReducer = (state = initialState, action) => {
	switch (action.type) {
		case "REQUEST_BALANCEOF":
			return {
				...state,
				loading: true,
			};
		case "REQUEST_TOTALSUPPLY":
			return {
				...state,
				supplyLoading: true,
			};
		case "GET_BALANCEOF":
			return {
				...state,
				balanceOf: action.payload,
				loading: false,
			};
		case "BALANCEOF_ERROR":
			return {
				...state,
				loading: false,
				balanceOf: 0,
			};
		case "GET_TOTALSUPPLY":
			return {
				...state,
				totalSupply: action.payload,
				supplyLoading: false,
			};
		case "TOTALSUPPLY_ERROR":
			return {
				...state,
				supplyLoading: false,
				totalSupply: "-",
			};
		default:
			return state;
	}
};

export default contractReducer;
