import React from "react";
import "./slider.css";
import { Image } from "@chakra-ui/react";
import "swiper/swiper-bundle.min.css";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import img5 from "../../assets/images/content/5.webp";
import img6 from "../../assets/images/content/6.webp";
import img7 from "../../assets/images/content/7.webp";
import img8 from "../../assets/images/content/8.webp";
import img9 from "../../assets/images/content/9.webp";

SwiperCore.use([Navigation, Pagination]);
SwiperCore.use([Autoplay]);

function Slider() {
  const { height, width } = useWindowDimensions();
  return (
    <Swiper
      slideToClickedSlide={true}
      autoplay={{ delay: 7000 }}
      slidesPerView={width <= 768 ? 3 : width <= 1024 ? 3 : width / 400}
      spaceBetween={10}
      pagination={{ clickable: true }}
      tag="section"
      wrapperTag="ul"
      id="main"
      centeredSlides={true}
      loop={true}
    >
      {[img5, img6, img7, img8, img9].map((image, i) => {
        return (
          <SwiperSlide key={i}>
            <Image src={image} bg="gray.200" />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

export default Slider;
