import React from "react";
import { Box, Container, Flex, Heading, Image, Text } from "@chakra-ui/react";
import BCLager from "../../assets/images/BCLager.webp";
import BCPorter from "../../assets/images/BCPorter.webp";
import BCStout from "../../assets/images/BCStout.webp";
import BCSuperDry from "../../assets/images/BCSuperDry.webp";
import BCAmber from "../../assets/images/BCAmber.webp";
import BCAle from "../../assets/images/BCAle.webp";
import BCIPA from "../../assets/images/BCIPA.webp";

const Team = () => {
	const team = [
		{ title: "BCSuperDry", image: BCSuperDry },
		{ title: "BCLager", image: BCLager },
		{ title: "BCStout", image: BCStout },
		{ title: "BCIPA", image: BCIPA },
		{ title: "BCAmber", image: BCAmber },
		{ title: "BCAle", image: BCAle },
		{ title: "BCPorter", image: BCPorter },
	];
	return (
		<Container
			as="section"
			maxW={"container.xl"}
			p={["0 1rem", null, "0 1.5rem"]}
			mb="5rem"
			id="team"
		>
			<Heading
				as="h1"
				fontFamily={"Poppins"}
				fontWeight={"700"}
				color={"yellow.400"}
				textAlign={["center", null, "left"]}
				fontSize={["3xl", "4xl"]}
			>
				Meet The Team
			</Heading>

			<Text mt={"1.5rem"} color={"gray.200"} fontFamily={"Poppins"}>
				The CBC team is a collection of developers, artists and marketers that
				believe in a better future for work.
			</Text>
			<Flex
				flexWrap={"wrap"}
				gridGap={"1.5rem"}
				mt="2rem"
				justifyContent={"center"}
			>
				{team.map((el, i) => {
					return (
						<Box key={i} w={["45%", null, "auto"]}>
							<Image src={el.image} bg="gray.200" maxW="18rem" w="100%" />
							<Text
								textAlign={"center"}
								fontFamily="Poppins"
								color="yellow.400"
								mt={"0.5rem"}
								fontSize={["xs", "sm", "md"]}
							>
								{el.title}
							</Text>
						</Box>
					);
				})}
			</Flex>
		</Container>
	);
};

export default Team;
