import {
	Box,
	Flex,
	Text,
	Alert,
	AlertIcon,
	HStack,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	Button,
	Center,
	Image,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "@chakra-ui/react";
import {
	balanceOf,
	totalSupply,
} from "../../../redux/contract/contractActions";
import { switchEthereumChain } from "../../../redux/blockchain/blockchainActions";
import { connect } from "../../../redux/blockchain/blockchainActions";
import { useState, useEffect } from "react";
import loadingImg from "../../../assets/images/beerLoading.gif";
import ETH from "../../../assets/svg/ETHLogo";

const MintingBox = () => {
	const dispatch = useDispatch();
	const userBalance = useSelector((state) => state.contract.balanceOf);
	const balanceLoading = useSelector((state) => state.contract.loading);
	const [ethPrice, setEthPrice] = useState("");
	const { account, networkID, smartContract, web3 } = useSelector(
		(state) => state.blockchain
	);
	const [quantity, setQuantity] = useState(1);
	const [claiming, setClaiming] = useState(false);

	useEffect(() => {
		getEthPrice();
	}, []);

	const getEthPrice = async () => {
		const data = await fetch(
			"https://api.etherscan.io/api?module=stats&action=ethprice&apikey=1V7AC7MG3Q8W8KVVUYIZY7QKGRAZIPQSW4"
		)
			.then((res) => res.json())
			.then((res) => Math.round(res.result.ethusd * 0.05 * 100) / 100);
		setEthPrice(data);
	};

	const changeValue = (value) => {
		//Used to change quantity
		setQuantity(value);
	};

	const mint = (quantity) => {
		if (!account.length) {
			dispatch(connect());
		} else if (networkID != 1) {
			dispatch(switchEthereumChain(setClaiming));
		} else {
			setClaiming(true);
			smartContract.methods
				.mint(quantity)
				.send({
					from: account,
					value: web3.utils.toWei((0.05 * quantity).toString(), "ether"),
					gas: 270000 * quantity,
				})
				.once("error", (err) => {
					console.log(err);
					setClaiming(false);
				})
				.then((res) => {
					setClaiming(false);
					dispatch(balanceOf(smartContract, account));
					dispatch(totalSupply(smartContract));
				});
		}
	};

	const loading = <Image boxSize="50px" mt={5} src={loadingImg} />;

	const getNetwork = (network) => {
		switch (
			parseInt(network) //Switch statement to check for network ID.
		) {
			case 1:
				return "Mainnet";
			case 3:
				return "Ropsten";
			case 4:
				return "Rinkeby";
			case 5:
				return "Goerli";
			default:
				return "unknown";
		}
	};

	return (
		<Box color={"gray.200"}>
			<Box>
				<Text fontSize="sm" fontFamily="Poppins" fontWeight={"400"}>
					Price Per Beer
				</Text>
				<HStack fontFamily="Poppins" fontWeight={"800"} alignItems="baseline">
					<Text fontSize={["2xl", "3xl"]}>0.05 ETH</Text>
					<Text fontSize="md" color="yellow.400">
						<Box as="span" fontFamily={"sans-serif"} fontWeight={"bold"}>
							≈{" "}
						</Box>
						${ethPrice}
					</Text>
				</HStack>
				<Text fontSize="sm" mt={1} fontFamily="Poppins">
					Enter how many you would like to mint here.
				</Text>
			</Box>

			<Flex bg="#3e3e3e6b" p="10px" alignItems="center" maxW={"500px"}>
				<Flex alignItems="center" w="33.33%">
					<ETH w="25px" h="25px" />
					<Box ml={3}>
						<Text fontFamily="Poppins" fontSize="xs" color="yellow.400">
							Cost
						</Text>
						<Text fontSize={"md"} fontFamily="Poppins" fontWeight={"800"}>
							{(quantity * 0.05).toFixed(2)} ETH
						</Text>
					</Box>
				</Flex>

				<Box w="33.33%">
					<Text
						textAlign="center"
						fontFamily="Poppins"
						fontWeight={"700"}
						color="gray.200"
					>
						Max: 10
					</Text>
				</Box>

				<Flex alignItems="center" w="33.33%" justifyContent="flex-end">
					<Box>
						<NumberInput
							maxW={16}
							defaultValue={1}
							min={1}
							max={10}
							step={1}
							precision={0.01}
							onChange={(value) => changeValue(value)}
						>
							<NumberInputField />
							<NumberInputStepper>
								<NumberIncrementStepper />
								<NumberDecrementStepper />
							</NumberInputStepper>
						</NumberInput>
					</Box>
				</Flex>
			</Flex>

			{networkID != 1 && networkID != "" ? (
				<Alert
					status="warning"
					mt={6}
					fontFamily="Poppins"
					color="black"
					maxW={"500px"}
					fontSize={["sm", "md"]}
				>
					<AlertIcon />
					{getNetwork(networkID) != "unknown"
						? `You are connected to ${getNetwork(networkID)} network.`
						: "You are not connected to Ethereum Mainnet"}
				</Alert>
			) : null}

			<Box>
				{!claiming ? (
					<Button
						mt={6}
						borderRadius="15px"
						w="100%"
						onClick={() => {
							mint(quantity);
						}}
						_hover={{ transform: "scale(1.05)" }}
						isDisabled={userBalance >= 100 || balanceLoading ? true : false}
						maxW={"500px"}
					>
						<Text color="#000" fontFamily="Poppins">
							{balanceLoading ? (
								<Spinner />
							) : userBalance >= 100 ? (
								"Max Mint Amount Reached"
							) : !account.length ? (
								"Connect Wallet"
							) : (
								"MINT"
							)}
						</Text>
					</Button>
				) : (
					<Center maxW={"500px"}>{loading}</Center>
				)}
			</Box>
		</Box>
	);
};

export default MintingBox;
